import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import TitleGroup from '../components/TitleGroup';

const Cooperate = () => {
  return (
    <section id='cooperate' name='cooperate' className='cooperate'>
      <div className='cooperate-container'>
        <h2>WHO COOPERATE</h2>
        <div className='cooperate-row'>
          <div className='cooperate-text text-container'>
            <TitleGroup title='Partnership' subtitle='合作伙伴' rtl={true} />
            <div className='text-content text-content-cooperate'>
              <span>小岛凸凸，整合创意与科技，与不同技术伙伴打造 “有想法，就能定制” 的目标，开启一个崭新的定制时代。 与此同时，也致力与各范畴创作者合作互动交流，打造定制及有趣的 IP联名产品。</span>
            </div>
          </div>
        </div>
        <div className='cooperate-brands'>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_1.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_2.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_3.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_4.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_5.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_6.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_7.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_8.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_9.png' alt='' />
          </div>
          <div class='cooperate-brands-logo'>
            <StaticImage className='cooperate-brands-img' placeholder='none' src='../assets/images/cooperate_logo_10.png' alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cooperate;
