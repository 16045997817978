import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Banner = () => {
  return (
    <section id='banner' name='banner' className='banner'>
      <div className='banner-el'>
        <div className='banner-el-wrap'>
          <div className='banner-el-island'>
            <StaticImage placeholder='none' src='../assets/images/island.png' alt='' />
          </div>
          <div className='banner-el-cloudl'>
            <StaticImage placeholder='none' src='../assets/images/cloud_left.png' alt='' />
          </div>
          <div className='banner-el-bird'>
            <StaticImage placeholder='none' src='../assets/images/bird.png' alt='' />
          </div>
          <div className='banner-el-plant'>
            <StaticImage placeholder='none' src='../assets/images/plant.png' alt='' />
          </div>
          <div className='banner-el-monitor-wrap'>
            <StaticImage placeholder='none' src='../assets/images/monitor.png' alt='' />
            <div className='banner-el-logo'>
              <div className='glitch'>
                <i className='iconfont icon-tootools'></i>
                <i className='iconfont icon-tootools before'></i>
                <i className='iconfont icon-tootools after'></i>
              </div>
            </div>
          </div>
          <div className='banner-el-logo-text'>
            <i className='iconfont icon-tootools-island'></i>
            {/* <br />
            敬请期待 Coming Soon! */}
          </div>
        </div>
      </div>
      <div className='banner-el-cloudr'>
        <StaticImage placeholder='none' src='../assets/images/cloud_right.png' alt='' />
      </div>
      <div className='banner-bg'>
        <StaticImage className='banner-bg-img' placeholder='none' src='../assets/images/yellow_cloud.png' alt='' />
      </div>
    </section>
  );
};

export default Banner;
