import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import TitleGroup from '../components/TitleGroup';

export default function Product() {
  return (
    <section id='product' name='product' className='product'>
      <div className='product-title'>
        <TitleGroup title='Company Products' subtitle='公司产品' />
      </div>
      <div className='product-container'>
        {/* <div className='product-item'>
          <StaticImage className='product-item-image' placeholder='none' src='../assets/images/product_01.png' alt='' />
          <div className='overlay'>
            <div className='text'>
              <span>服装类</span>
            </div>
          </div>
        </div>
        <div className='product-item'>
          <StaticImage className='product-item-image' placeholder='none' src='../assets/images/product_02.png' alt='' />
          <div className='overlay'>
            <div className='text'>
              <span>玩偶类</span>
            </div>
          </div>
        </div> */}

        <div className='product-item'>
          <StaticImage className='product-item-image' placeholder='none' src='../assets/images/product_01.png' alt='' />
        </div>
        <div className='product-item'>
          <StaticImage className='product-item-image' placeholder='none' src='../assets/images/product_02.png' alt='' />
        </div>
        <div className='product-item'>
          <StaticImage className='product-item-image' placeholder='none' src='../assets/images/product_03.png' alt='' />
        </div>
      </div>
    </section>
  );
}
