import React from 'react';
import TitleGroup from '../components/TitleGroup';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const culture = [
  { icon: 'bulb', title: '好奇心', subtitle: 'Curiosity', content: '永远好奇，永远探索，对生活永远热情' },
  { icon: 'cooperation', title: '合作共赢', subtitle: 'Cooperate', content: '为用户提供创意迸发的创作平台与体验，与合作方创造更高商业价值。' },
  { icon: 'diamond', title: '极致专注', subtitle: 'Concentrate', content: '专注极致，创造每个 细节的完美，用极致态度服务每一个用户。' },
  { icon: 'heart', title: '责任心', subtitle: 'Commitment', content: '自我责任感，去做到对自我的承诺。' },
];

const About = () => {
  return (
    <section name='about' className='about'>
      <div className='about-bg'>
        <StaticImage className='about-bg-img' placeholder='none' src='../assets/images/about_bg.png' alt='' />
      </div>
      <div className='about-container'>
        <h2>WHO ARE WE</h2>
        <div className='about-content'>
          <div className='about-row' id='about'>
            <div className='about-text text-container'>
              <TitleGroup title='Company Introduction' subtitle='公司介绍' />
              <div className='text-content text-content-01'>
                上海瑞杰印信息科技有限公司成立于2021年，旗下有多个3D打印以及一体化定制服务产品，立志于为新一代用户提供定制化C2M服务，旗下产品小岛凸凸小程序在传统电商模式的基础上增加玩偶及服饰定制化服务，以强中心化平台定位深入管理把控产品质量与价格。
                <br />
                同时作为年轻人定制化创作社区平台，小岛凸凸通过持续集中潮流定制话题内容，不断为年轻人提供创意发声平台。
              </div>
            </div>
            <div className='about-image'>
              <StaticImage placeholder='none' src='../assets/images/about_01.png' alt='' />
            </div>
          </div>
          <div className='about-row about-row-reverse'>
            <div className='about-image'>
              <StaticImage placeholder='none' src='../assets/images/about_02.png' alt='' />
            </div>
            <div className='about-text text-container'>
              <TitleGroup title='Platform Introduction' subtitle='平台介绍' rtl={true} />
              <div className='text-content text-content-02'>
                小岛凸凸，次世代全新轻定制服务平台，突破传统定制格局，体验全新定制乐趣。多元化独立艺术家联名设计作品；艺术家联名设计及创作中心已全新在微信官方小程序上线，用户可根据个人创意灵感定制多款不同载体（纯棉卫衣，夏季T恤，儿童卫衣，帆布袋，马克杯等）并采用高科技印刷技术，将用户定制图形呈现于产品上。同时平台具有海量元素贴纸及形状，用户发挥想象可定制任何想要的图案，为用户提供无限创意空间。
                <br />
                同步个人定制头像服务也即将上线，届时用户可通过官方微信小程序小岛凸凸 Tootools 进入创作中心选择并制作用户专属头像。小岛凸凸原创系列元素为你的定制带来无限创意灵感。
              </div>
            </div>
          </div>
          <div className='about-row about-row' id='mcService'>
            <div className='about-text text-container'>
              <TitleGroup title='Mass Customization' subtitle='企业定制' rtl={true} />
              <div className='text-content text-content-03'>
                ⼩岛凸凸企业批量定制，跳出传统厂房式工衣生产概念，讲求
                <br />
                个性创意，保证企业统⼀定制的需求下也让员⼯拥有⾃⼰设计
                <br />
                的空间，让企业员⼯服既有企业特⾊⼜能展示员⼯个性。
              </div>
              <Link class='about-link' to='/mass-customization'>
                了解更多
              </Link>
            </div>
            <div className='about-image'>
              <StaticImage placeholder='none' src='../assets/images/about_03.jpg' alt='' />
            </div>
          </div>
          <div className='about-culture-container'>
            <TitleGroup title='Company Culture' subtitle='企业文化' />
            <div className='about-culture-items'>
              <div className='about-culture'>
                {culture.map(({ icon, title, subtitle, content }, index) => (
                  <div className='about-culture-item' key={index}>
                    <div className='about-culture-item-title'>
                      <i className={`iconfont icon-${icon}`}></i> {title}
                    </div>
                    <div className='about-culture-item-subtitle'>
                      <span>{subtitle}</span>
                    </div>
                    <div className='about-culture-item-content'>{content}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
