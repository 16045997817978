import React from 'react';

import Header from '../components/Header';
import Banner from '../layouts/Banner';
import About from '../layouts/About';
import Cooperate from '../layouts/Cooperate';
import Product from '../layouts/product';
import Contact from '../layouts/contact';
import Seo from '../components/Seo';

import '../assets/css/index.scss';

const navigators = [
  {
    name: '关于我们',
    id: 'about',
    isActive: false,
  },
    {
    name: '企业定制',
    id: 'mcService',
    isActive: false,
  },
  {
    name: '合作伙伴',
    id: 'cooperate',
    isActive: false,
  },
  {
    name: '公司产品',
    id: 'product',
    isActive: false,
  },
  {
    name: '联系我们',
    id: 'contact',
    isActive: false,
  },
];

const IndexPage = () => {
  return (
    <main>
      <Seo />
      <Header navigators={navigators}/>
      <Banner />
      <About />
      <Cooperate />
      <Product />
      <Contact email='cooperation@tootools.cn' phone='18100095751' contactName={'葛先生'} />
    </main>
  );
};

export default IndexPage;
